import React, { useContext, useState } from "react"

// Context
import { CursorContext } from "../context/cursorContext"

// Styles
import {
  PricingContent,
  PricingSection,
  PricingSectionInner,
  TierList,
  Tier,
  TierPrices,
  Price,
  RadioSwitch,
  RadioSwitchItem,
  Switch,
} from "../styles/pricingStyles"
import { Container } from "../styles/globalStyles"

const Pricing = ({ title, seasons, prices, additionalInfos }) => {
  const { handleMouseHover } = useContext(CursorContext)
  const [season, setSeason] = useState("low")
  const [isChecked, setIsChecked] = useState(true)

  const toggleSeason = e => {
    setSeason(e.target.value)
  }

  const handleChanges = () => {
    setIsChecked(!isChecked)
  }

  return (
    <PricingSection>
      <Container>
        <PricingSectionInner>
          <PricingContent>
            <h2>{title}</h2>
            <Switch
              onMouseEnter={e => handleMouseHover(e, "hovered")}
              onMouseLeave={e => handleMouseHover(e, "hovered")}
            >
              <RadioSwitch className="radio-switch">
                <RadioSwitchItem>
                  <input
                    type="radio"
                    id="low"
                    name="radio-switch-name"
                    value="low"
                    onClick={toggleSeason}
                    onChange={handleChanges}
                    checked={isChecked}
                  />
                  <label htmlFor="low">Low Season</label>
                </RadioSwitchItem>
                <RadioSwitchItem>
                  <input
                    type="radio"
                    id="high"
                    name="radio-switch-name"
                    value="high"
                    onClick={toggleSeason}
                    onChange={handleChanges}
                    checked={!isChecked}
                  />
                  <label htmlFor="high">High Season</label>
                </RadioSwitchItem>
              </RadioSwitch>
              <p>{season === "low" ? seasons.lowSeason : seasons.highSeason}</p>
            </Switch>
            <TierList>
              {prices.map(({ title, discount, single, double }, i) => {
                return (
                  <Tier key={i} discount={discount}>
                    <h4>{title}</h4>
                    <TierPrices>
                      <Price>
                        <p>1 Guest</p>
                        <div>
                          <p>€</p>
                          <h1>
                            {season === "low"
                              ? single.lowSeason
                              : single.highSeason}
                          </h1>
                        </div>
                      </Price>
                      <Price>
                        <p>2 Guest</p>
                        <div>
                          <p>€</p>
                          <h1>
                            {season === "low"
                              ? double.lowSeason
                              : double.highSeason}
                          </h1>
                        </div>
                      </Price>
                    </TierPrices>
                  </Tier>
                )
              })}
            </TierList>
            <p>{additionalInfos}</p>
          </PricingContent>
        </PricingSectionInner>
      </Container>
    </PricingSection>
  )
}

export default Pricing
