import styled, { css } from "styled-components"
import { Grid } from "./globalStyles"

export const PricingSection = styled.section`
  padding-block: var(--space-m);
`

export const PricingSectionInner = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    ${Grid}
  }
`

export const PricingContent = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    grid-column: 2 / span 4;
    padding-inline: var(--space-n);
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    grid-column: 4 / span 5;
  }
`

export const TierList = styled.ul``

export const Tier = styled.li`
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  margin-bottom: var(--space-n);

  ${({ discount }) =>
    discount &&
    css`
      h4:after {
        content: "${({ discount }) => discount}";
        padding: 2px 6px;
        margin-inline: var(--space-s);
        background-color: var(--accent-color);
        color: white;
        border-radius: 50em;
      }
    `}
`

export const TierPrices = styled.div`
  display: flex;
  border-top: 1px solid #c4c4c4;
`

export const Price = styled.div`
  flex-basis: 50%;
  padding: var(--space-s);
  font-family: var(--support-typeface);

  &:first-child {
    border-right: 1px solid #c4c4c4;
  }

  > p {
    font-size: calc(var(--size-s) * 1em);
  }

  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-s);

    > h1,
    p {
      margin-bottom: 0;
      font-weight: 700;
    }
  }
`

// Switch test
export const Switch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  > p {
    color: var(--orange-summer-lighter);
    font-family: var(--support-typeface);
    font-size: calc(var(--size-xs) * 1em);
    margin-bottom: calc(var(--leading) / var(--size-xs) * 1);
    font-weight: 500;
  }
`

export const RadioSwitch = styled.ul`
  width: 100%;
  position: relative;
  display: inline-block; // flexbox fallback
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid var(--orange-summer-lighter);
  list-style: none;
  height: var(--space-m);
  margin-block-end: 0;

  &:focus-within,
  &:active {
    box-shadow: 0 0 0 2px #ffd7c8;
  }
`

export const RadioSwitchItem = styled.li`
  padding: 2px;
  display: flex;
  flex-basis: 100%;
  font-family: var(--support-typeface);
  font-size: calc(var(--size-s) * 1em);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--orange-summer-lighter);

  input {
    position: absolute;
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    white-space: nowrap;

    &:checked ~ label {
      color: white;
      background-color: var(--link-color);
    }
  }

  label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    transition: all 0.3s;
    border-radius: 2px;
  }
`
